<template>
  <header>
    <div class="header-inner">
      <router-link :to="{ name: 'Home' }">
        <div class="logo-image">
          <img src="../assets/logo.png" alt="" /><p>OPTIONS</p>
        </div>
      </router-link>
      <h2 class="subttl">4択支援アプリ</h2>
    </div>
  </header>
</template>

<script>
export default {};
</script>

<style>
</style>