<template>
  
        <footer>(C) 2022 OPTIONS 4択支援Webアプリ</footer>
</template>

<script>
export default {

}
</script>

<style>

</style>